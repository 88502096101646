import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today and Sunday the Ville is hosting a USAW Weightlifting Level 1
Coaching Certification Course.  `}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`***`}</strong>{`The gym will be closed for this event.  Saturday is a
scheduled Rest Day but we will make up Sunday’s WOD the following week
by eliminating the next Rest Day to get back on track.`}{`*`}{`*`}{`***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Our Annual Thanksgiving Day wod will be at 9:00am next Thursday!
 This workout is free to all so bring a friend!  This will be the only
class of the day. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      